.error-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); 
}

.error-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 32px;
  gap: 32px;
  width: 500px;
  height: 399px;
  overflow-y: none;
//   background: $color-background;
  box-shadow: 0px 264px 106px rgba(0, 0, 0, 0.01), 0px 149px 89px rgba(0, 0, 0, 0.05), 0px 66px 66px rgba(0, 0, 0, 0.09), 0px 17px 36px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.error-icon {
  font-style: normal;
  font-weight: 400;
  font-size: 61px;
  line-height: 61px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #DF2935;
  width: 61px;
  height: 61px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.popup-header-text {
  font-family: 'Bebas Neue', sans-serif;
  font-weight: 700;
  font-size: 39px;
  line-height: 39px; /* 100% */
//   color: $color-text;
}

.popup-body-text {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  margin-top: 32px;
  
  color: #6D7B88;
}
.popup-body-text a {
//   color: $color-primary;
}

.popup-body-text a:hover {
//   color: $color-primary;  // Change the hover color if needed.
}

.close-button {
  box-sizing: border-box;
//   border: 1px solid $color-primary;
  border-radius: 4px;
//   background-color: $color-background;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  text-align: center;
  text-transform: uppercase;
  /* Foundation /Red/red-500 */

//   color: $color-primary;
  padding: 10px 20px;
  margin-top: 10px;
}

.close-button:focus {
//   outline: 2px solid $color-primary; /* The color you want */
  box-shadow: none;
}

.text-danger { padding-top: 10px}