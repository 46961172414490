.garden {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Align center horizontally */
    align-items: center; /* Align center vertically */
  
    .gardenImg {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  
      @media (min-width: 80%) {
        width: calc(20% - 10px); /* 10px is for margin between images */
      }
    }
  }
  