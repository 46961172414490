.heroImg {
  width: 100%;
}



// custom styling for programs
.mypantryplanner {
  .header {
    background: #201d1e;
  }
}