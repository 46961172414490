.content-tiktok {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, auto));
      grid-template-rows: auto;
      justify-content: center;
      margin-bottom: 2.5em;
  
      .content-item {
        border-radius: 0;
        border: 0;max-width: 56vh;
       
        .parent {
          left: 0; 
          width: 100%; 
          height: 0; 
          position: relative; 
          padding-bottom: 165.7778%; 
          padding-top: 120px;
          iframe {
            top: 0; 
            left: 0; 
            width: 100%; 
            height: 100%; 
            position: absolute; 
            border: 0;
          }
        }    
      }
    }
  
  