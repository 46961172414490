// AgeGate.scss

$primary-color: #007bff;
$background-color: #f4f4f4;
$card-background-color: white;
$card-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

.age-gate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.05); /* Set alpha value for transparency */
}

  

.age-gate {
  background-color: $card-background-color;
  padding: 20px;
  border-radius: 8px;
  box-shadow: $card-shadow;
  text-align: center;
  width: 80%;
  max-width: 400px;

  &.fade-out {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s, transform 0.5s;
  }
}

.brand-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.date-inputs {
  display: flex;
  justify-content: space-between;

  input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

button {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.error-message {
  color: red;
  margin-top: 10px;
}

.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  // width: 100vw;
  // height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Set alpha value for transparency */
  z-index: 9998; /* Below the age gate container */
  transition: opacity 0.5s;
  opacity: 0;
}