
.offer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 144px;
  gap: 4px;
//   background: $color-faq-background;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.heading {
  // width: 1440px;
  height: 61px;
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 700;
  font-size: 61px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
//   color: $color-footer;
  flex: none;
  flex-grow: 0;
  order: 1;
}

.earn-block {
  // width: 1440px;
height: 39px;
font-family: 'Bebas Neue';
font-style: normal;
font-weight: 700;
font-size: 39px;
line-height: 100%;
display: flex;
align-items: center;
text-align: center;
// color: $color-footer;
order: 2;
}

.clipped-rewards-block {
//   color: $color-footer;
  // width: 1440px;
  height: 20px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  
  text-align: center;  
  
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}

.clipped-rewards-block a {
//   color: $color-primary;
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .offer-container {
    /* Mobile-specific styles */
    padding: 24px;
  }

  .heading {
    /* Mobile-specific styles */
    font-size: 36px;
  }

  .earn-block {
    /* Mobile-specific styles */
    font-size: 24px;
  }

  .clipped-rewards-block {
    /* Mobile-specific styles */
    font-size: 16px;
  }
}