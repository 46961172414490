@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

$color-background: #fff;
$color-faq-background: #e1d5ff;

$color-enrollment-background: #e1d5ff;

$color-primary: #8757FF;

$color-divider-2: #fff;
$color-divider-3: #fff;
$color-divider-4: #fff;

$color-footer: #8757FF;
$color-text: #000;

.qualifying-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); 
}

.modal-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: $color-primary;
  color: $color-background;
  position: relative;
  
  h2 {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 100%;
    color: $color-primary;
    margin: 0;
  }

  .close-button {
    color: $color-background;
    background-color: $color-primary;
    border: none;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.modal-body {
  max-width: 100%;
  padding: 10px;

  .brand-list {
    margin-bottom: 20px;

    h2 {
      font-family: 'Bebas Neue', sans-serif;
      font-weight: 700;
      font-size: 25px;
      line-height: 120%;
      color: $color-primary;
      margin-bottom: 10px;
    }

    .product-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      line-height: 100%;

      // div {
      //   // Additional styling for each product item if needed
      // }
    }
  }

  .divider {
    margin-top: 25px;
    height: 1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-button {
  box-sizing: border-box;
  border: 1px solid $color-primary;
  border-radius: 4px;
  background-color: #FFFFFF;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;
  color: $color-primary;
  padding: 10px 20px;
  margin-top: 10px;
  
  &:focus {
    outline: 2px solid $color-primary;
    box-shadow: none;
  }
}

.qualifying-products-heading {
  color: #ffffff;
  background: #BE0042;
  text-align: left;
  size: 12px;
}

.popup-body-text {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  margin-top: 32px;
  color: #6D7B88;
  
  a {
    color: #BE0042;
    
    &:hover {
      color: #DF2935;
    }
  }
}

.error-icon {
  font-style: normal;
  font-weight: 400;
  font-size: 61px;
  line-height: 61px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #DF2935;
  width: 61px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.upc {
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: #999999;
}