@font-face {
  font-family: "Switzer";
  src: url("../../public/fonts/Switzer-Regular.eot");
  src: url("../../public/fonts/Switzer-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Switzer-Regular.woff2") format("woff2"),
    url("../../public/fonts/Switzer-Regular.woff") format("woff"),
    url("../../public/fonts/Switzer-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("../../public/fonts/Switzer-Bold.eot");
  src: url("../../public/fonts/Switzer-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Switzer-Bold.woff2") format("woff2"),
    url("../../public/fonts/Switzer-Bold.woff") format("woff"),
    url("../../public/fonts/Switzer-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Switzer";
  src: url("../../public/fonts/Switzer-Italic.eot");
  src: url("../../public/fonts/Switzer-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/Switzer-Italic.woff2") format("woff2"),
    url("../../public/fonts/Switzer-Italic.woff") format("woff"),
    url("../../public/fonts/Switzer-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "InterMedium";
  src: url("../../public/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "InterBold";
  src: url("../../public/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
