.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: none;
  order: 8;
  flex-grow: 0;
  padding: 50px;
  width: 100vw;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  @media (max-width: 768px) {
    padding: 0px;
    padding: 25px;
  }
  
  .header {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-size: 49px;
    line-height: 100%;
    margin: 10px;
  }
  
  .faq-content {
    width: 80%;

    .card {
      margin: 5px;

      .card-header {

        display: flex;
        justify-content: space-between;
        align-items: center; /* Add this line to vertically center the content */
        flex-direction: row;
          

        .question-container {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .question-text {
          text-align: left;
          margin-right: 10px;
          width: 80%;
        }

        .chevron-icon {
          width: 15%;
          text-align: right;
        }
      }
  
        /* Remove the unnecessary ID selector */
      .collapse {
        background: #F5F7F9;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      }
  
      .card-body {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        text-align: left;
        line-height: 120%;
        
        p{

          margin-bottom: 0px;
        }
      }
    }
  }
}
  